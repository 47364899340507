import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { i18nRoute } from 'routes';

export default function Newsletter() {
	const { t, i18n } = useTranslation(['common']);

	const handleSubmit = async (event) => {
		event.preventDefault();

		const data = new FormData(event.currentTarget);
		const user = {
			email: data.get('email'),
		};
		const response = await fetch('/api/newsletter', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ email: user.email }),
		});
		const { message } = await response.json();
		console.log(message);
	};

	return (
		<div className="bg-indigo-700 py-16 sm:py-24 lg:py-32">
			<div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
				<div className="text-3xl font-bold tracking-tight text-white sm:text-4xl lg:col-span-7">
					<h2 className="inline sm:block lg:inline xl:block">
						{t('common:newsletter.title')}
					</h2>{' '}
					<p className="inline sm:block lg:inline xl:block">
						{t('common:newsletter.subtitle')}
					</p>
				</div>
				<form
					className="w-full max-w-md lg:col-span-5 lg:pt-2"
					noValidate
					onSubmit={handleSubmit}
				>
					<div className="flex gap-x-4">
						<label htmlFor="email-address" className="sr-only">
							{t('common:labels.emailAddress')}
						</label>
						<input
							id="email-address"
							name="email"
							type="email"
							autoComplete="email"
							required
							className="min-w-0 flex-auto rounded-md border-0 bg-white/10 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-white/75 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6"
							placeholder={t('common:labels.emailPlaceholder')}
						/>
						<button
							type="submit"
							className="flex-none rounded-md bg-white py-2.5 px-3.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
						>
							{t('common:buttons.notifyMe')}
						</button>
					</div>
					<p className="mt-4 text-sm leading-6 text-gray-300">
						{t('common:newsletter.policy')}{' '}
						<Link
							href={i18nRoute('/privacy', i18n.language)}
							className="font-semibold text-white hover:text-indigo-50"
						>
							{t('common:labels.privacyPolicy')}
							{'.'}
						</Link>
					</p>
				</form>
			</div>
		</div>
	);
}
