import LocationSearch from '@/images/undraw_location_search_re_ttoj.svg';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { MdEvent, MdPlace, MdTripOrigin } from 'react-icons/md';

export default function SearchHelpSection() {
	const { t } = useTranslation(['home', 'common']);

	const features = [
		{
			name: t('home:sections.searchHelp.features.origin.title'),
			description: t('home:sections.searchHelp.features.origin.subtitle'),
			icon: MdTripOrigin,
		},
		{
			name: t('home:sections.searchHelp.features.destination.title'),
			description: t('home:sections.searchHelp.features.destination.subtitle'),
			icon: MdPlace,
		},
		{
			name: t('home:sections.searchHelp.features.date.title'),
			description: t('home:sections.searchHelp.features.date.subtitle'),
			icon: MdEvent,
		},
	];

	return (
		<div className="overflow-hidden bg-white py-12 sm:py-16">
			<div className="mx-auto max-w-7xl px-4 lg:px-8">
				<div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
					<div className="lg:ml-auto lg:pt-4 lg:pl-4">
						<div className="lg:max-w-lg">
							<h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
								{t('home:sections.searchHelp.overtitle')}
							</h2>
							<h3 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
								{t('home:sections.searchHelp.title')}
							</h3>
							<h4 className="mt-6 text-lg leading-8 text-gray-600">
								{t('home:sections.searchHelp.subtitle')}
							</h4>
							<dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
								{features.map((feature) => (
									<div key={feature.name} className="relative pl-9">
										<dt className="inline font-semibold text-gray-900">
											<feature.icon
												className="absolute top-1 left-1 h-6 w-6 text-indigo-600"
												aria-hidden="true"
											/>
											{feature.name}
											{'.'}
										</dt>{' '}
										<dd className="inline">{feature.description}</dd>
									</div>
								))}
							</dl>
						</div>
					</div>
					<div className="flex items-start justify-end lg:order-first">
						<Image
							src={LocationSearch}
							alt={t('home:sections.searchHelp.title')}
							title={t('home:sections.searchHelp.title')}
							className="w-[20rem] max-w-none md:mx-auto md:w-[30rem] lg:w-[45rem]"
							width={2432}
							height={1442}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
