import { project } from '@/lib/project';
import { BoltIcon, GlobeAltIcon, ScaleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';

export default function FeaturesSection() {
	const { t } = useTranslation(['home']);
	const features = [
		{
			name: t('home:sections.features.plan.title'),
			description: t('home:sections.features.plan.subtitle', {
				project: project.name,
			}),
			icon: GlobeAltIcon,
		},
		{
			name: t('home:sections.features.compare.title'),
			description: t('home:sections.features.compare.subtitle', {
				project: project.name,
			}),
			icon: ScaleIcon,
		},
		{
			name: t('home:sections.features.book.title'),
			description: t('home:sections.features.book.subtitle', {
				project: project.name,
			}),
			icon: BoltIcon,
		},
	];
	return (
		<div className="bg-white py-24 lg:py-32">
			<div className="mx-auto max-w-7xl px-4 lg:px-8">
				<div className="mx-auto max-w-2xl md:text-center">
					<span className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
						{t('home:sections.features.overtitle')}
					</span>
					<h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
						{t('home:sections.features.title')}
					</h2>
					<p className="mt-6 text-lg leading-8 text-gray-600">
						{t('home:sections.features.subtitle', { project: project.name })}
					</p>
				</div>
				<div className="mx-auto mt-16 max-w-2xl lg:mt-24 lg:max-w-none">
					<dl className="grid grid-cols-1 gap-x-8 gap-y-16 md:grid-cols-3 lg:max-w-none">
						{features.map((feature) => (
							<div key={feature.name} className="flex flex-col md:items-center">
								<dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
									<feature.icon
										className="h-5 w-5 flex-none text-indigo-600"
										aria-hidden="true"
									/>
									<h3>{feature.name}</h3>
								</dt>
								<dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600 md:text-center">
									<p className="flex-auto">{feature.description}</p>
									{/* <p className="mt-6">
										<a
											href={feature.href}
											className="text-base font-semibold leading-7 text-indigo-600"
										>
											Learn more <span aria-hidden="true">→</span>
										</a>
									</p> */}
								</dd>
							</div>
						))}
					</dl>
				</div>
			</div>
		</div>
	);
}
