import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { i18nRoute } from 'routes';

export default function RouteLink({
	className,
	children,
	route,
	transportType,
}) {
	const { t, i18n } = useTranslation('common');

	const href = i18nRoute(`/tickets/[routeId]`, i18n.language, {
		routeId: route.slug,
	});

	return (
		<Link
			className={className}
			title={t(
				`relatedRoutes.${
					transportType ? transportType + '_' : ''
				}tickets_from_to`,
				{
					origin: route.originName || route.origin.shortName,
					destination: route.destinationName || route.destination.shortName,
				}
			)}
			href={href}
		>
			{children ? (
				children
			) : (
				<>
					<span className="truncate">
						{t('relatedRoutes.to', {
							origin: route.originName || route.origin.shortName,
							destination: route.destinationName || route.destination.shortName,
						})}
					</span>
				</>
			)}
		</Link>
	);
}
