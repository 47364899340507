import { classNames } from '@/lib/utils';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { i18nRoute } from 'routes';

export default function CompanyLink({ className, children, company }) {
	const router = useRouter();
	const { i18n } = useTranslation('common');

	const href =
		company.transportType && company.transportType.toLowerCase() === 'train'
			? `/${router.locale}/companies/${company.id}`
			: i18nRoute(`/companies/[companyId]`, i18n.language, {
					companyId: company.id,
			  });

	return (
		<Link
			title={company.name}
			href={href}
			className={classNames('focus:outline-none', className)}
		>
			{children ? children : company.name}
		</Link>
	);
}
