import RouteLink from '@/components/navigation/RouteLink';
import { useTranslation } from 'next-i18next';

export default function TopRoutesSection({ routes }) {
	const { t } = useTranslation(['home']);

	if (!routes || routes.length === 0) {
		return null;
	}

	return (
		<div className="bg-white">
			<div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 lg:px-8">
				<div className="text-center">
					<h2 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
						{t(`home:sections.topRoutes.overtitle`)}
					</h2>
					<h3 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
						{t(`home:sections.topRoutes.title`)}
					</h3>
					<p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600">
						{t(`home:sections.topRoutes.subtitle`)}
					</p>
				</div>
				<div className="mt-14 grid grid-cols-1 gap-2 space-y-2 md:grid-cols-2 md:gap-0 lg:grid-cols-3 lg:gap-x-12">
					{routes.map((route) => (
						<RouteLink
							key={route.id}
							route={route}
							className="flex items-center justify-center px-2 py-2 text-base font-semibold leading-7 text-indigo-600"
						/>
					))}
				</div>
			</div>
		</div>
	);
}
