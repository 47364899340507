import { StaticLogo } from '@/components/ui/StaticLogos.jsx';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { i18nRoute } from 'routes';

export default function CompanyListSection({ companies }) {
	const { t, i18n } = useTranslation(['home', 'common']);
	if (!companies || companies.length === 0) {
		return null;
	}
	return (
		<div className="bg-white">
			<div className="mx-auto max-w-7xl py-12 px-4 lg:px-8 lg:py-32">
				<div className="lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
					<div className="lg:pr-8">
						<div className="mb-10 inline-flex space-x-6">
							<span className="shrink-0 rounded-full bg-indigo-600/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-700 ring-1 ring-inset ring-indigo-600/10">
								{t('common:labels.whyChooseUs')}
							</span>
							<span className="inline-flex shrink-0 items-center space-x-2 text-sm font-medium leading-6 text-gray-600">
								<span>{t('common:labels.totalCompanies')}</span>

								<ChevronRightIcon
									className="h-5 w-5 text-gray-400"
									aria-hidden="true"
								/>
							</span>
						</div>
						<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
							{t(`home:sections.busCompanies.title`)}
						</h2>
						<p className="mt-6 max-w-3xl text-lg leading-8 text-gray-600">
							{t(`home:sections.busCompanies.subtitle`)}
						</p>
						<div className="mt-8 sm:flex">
							<div className="rounded-md shadow">
								<Link
									href={i18nRoute(`/companies`, i18n.language)}
									title={t(`home:sections.busCompanies.subtitle`)}
									className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium capitalize text-white hover:bg-indigo-700"
								>
									{t('common:buttons.allCompanies')}
								</Link>
							</div>
						</div>
					</div>
					<div className="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
						{companies.slice(0, 6).map((company) => (
							<Link
								href={i18nRoute('/companies/[companyId]', i18n.language, {
									companyId: company.id,
								})}
								key={company.id}
								title={company.name}
								className="col-span-1 flex justify-center bg-gray-50 py-8 px-8"
							>
								<span className="sr-only">{company.name}</span>
								<Image
									className="max-h-12 w-auto grayscale hover:grayscale-0"
									src={StaticLogo({ id: company.id })}
									alt={`${company.name} logo`}
									title={company.name}
								/>
							</Link>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}
