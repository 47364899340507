import DestinationLink from '@/components/navigation/DestinationLink';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

export default function DestinationListSection({ items }) {
	const { t } = useTranslation(['home', 'common']);

	return (
		<div className="bg-indigo-50 py-12 sm:py-16">
			<div className="mx-auto max-w-7xl px-4 lg:px-8">
				<div className="mx-auto max-w-4xl text-center">
					<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
						{t('home:sections.destinations.title')}
					</h2>
					<p className="mt-3 text-lg text-gray-600 sm:mt-4">
						{t('home:sections.destinations.subtitle')}
					</p>
				</div>
			</div>
			<div className="mt-10">
				<div className="relative">
					<div className="relative mx-auto max-w-7xl px-6 lg:px-8">
						<div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 lg:gap-12">
							{items.map((destination) => (
								<div
									className="relative"
									key={destination.id}
									id={destination.id}
								>
									<DestinationLink destination={destination}>
										<span className="sr-only">
											{t('common:relatedRoutes.bus_tickets_to', {
												locationName: destination.shortName,
											})}
										</span>

										<div className="group relative block aspect-[4/3] w-full overflow-hidden rounded-lg transition delay-150 duration-300 ease-in-out focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 hover:-translate-y-1 hover:scale-105">
											<Image
												title={destination.longName}
												sizes="100vw"
												width="0"
												height="0"
												className="pointer-events-none h-full w-auto object-cover"
												src={destination.imageUrl}
												alt={destination.longName}
											/>
										</div>
									</DestinationLink>
									<h3 className="pointer-events-none absolute bottom-2 left-2 block truncate rounded-lg bg-white/30 px-4 py-2 font-medium text-white backdrop-blur-xl">
										<span className="sr-only">
											{t('common:relatedRoutes.bus_tickets_to', {
												locationName: '',
											})}
										</span>
										{destination.longName}
									</h3>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
