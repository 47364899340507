import { project } from '@/lib/project';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { i18nRoute } from 'routes';
import { StationCardContent } from '../stations/StationCard';

export function StationSectionContent({ station }) {
	const { t, i18n } = useTranslation(['home', 'common']);
	return (
		// <div key={station.id} className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
		<div>
			<div className="truncate">
				<h3
					id={station.id}
					className="flex text-base font-semibold leading-7 text-gray-900"
				>
					{station.name}
				</h3>
				<span className=" break-words text-sm capitalize text-gray-600">
					{station.address}
				</span>
			</div>
			<div className="mt-6">
				<div className="flex gap-x-3"></div>
				<Image
					width={400}
					height={200}
					alt={`${station.name} map`}
					title={`${station.name} map`}
					className="h-auto w-full rounded-2xl object-cover"
					src={`https://maps.googleapis.com/maps/api/staticmap?center=${station.latitude},${station.longitude}&zoom=15&size=300x150&maptype=roadmap&scale=2&markers=color:red|label:${station.name}|${station.latitude},${station.longitude}&key=${project.googleMapsApiKey}`}
				/>
			</div>
			<Link
				href={i18nRoute('/stations/[stationId]', i18n.language, {
					stationId: station.slug,
				})}
				title={station.name}
				aria-describedby={station.name}
				className="mt-10 block rounded-md bg-indigo-600 py-2 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
			>
				{t('common:buttons.viewStation')}
			</Link>
			<p className="mt-10 whitespace-normal text-sm font-semibold leading-6 text-gray-900 line-clamp-6">
				{station.description}
			</p>

			{/* {station.amenities && (
				<ul
					role="list"
					className="mt-6 space-y-3 text-sm leading-6 text-gray-600"
				>
					{Object.keys(station.amenities)
						.sort()
						.map((key) => (
							<li key={key} className="flex gap-x-3 capitalize">
								<CheckCircleIcon
									className="h-6 w-5 flex-none text-indigo-600"
									aria-hidden="true"
								/>
								{t(`common:stations.${key}`)} ({station.amenities[key].length})
							</li>
						))}
				</ul>
			)} */}
		</div>
	);
}
export default function StationsSections({ stations }) {
	const { t, i18n } = useTranslation(['home', 'common']);
	if (!stations || stations.length === 0) {
		return null;
	}
	return (
		<div className="bg-white py-12 sm:pb-32">
			<div className="mx-auto max-w-7xl px-4 lg:px-8">
				<div className="mx-auto max-w-4xl sm:text-center">
					<span className="text-base font-semibold leading-7 text-indigo-600">
						{t('home:sections.stations.overtitle')}
					</span>
					<h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
						{t('home:sections.stations.title')}
					</h2>
				</div>
				<p className="mx-auto mt-6 max-w-3xl text-lg leading-8 text-gray-600 sm:text-center">
					{t('home:sections.stations.subtitle')}
				</p>
				<div className="mt-20 flow-root">
					<div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-y-0 lg:divide-x xl:-mx-4">
						{stations.map((station) => (
							<div className="p-4 lg:p-8" key={station.id}>
								<StationCardContent station={station} includeMapInside />
							</div>
						))}
					</div>
				</div>

				<div className="mt-20 flex flex-col items-start gap-y-6 gap-x-8 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
					<div className="lg:min-w-0 lg:flex-1">
						<h3 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
							{t('home:sections.stations.viewAll.title')}
						</h3>
						<p className="mt-1 text-base leading-7 text-gray-600">
							{t('home:sections.stations.viewAll.subtitle')}
						</p>
					</div>
					<Link
						title={t('home:sections.stations.viewAll.title')}
						href={i18nRoute('/stations', i18n.language)}
						className="rounded-md px-3.5 py-2 text-sm font-semibold capitalize leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
					>
						{t('common:buttons.viewAll')} <span aria-hidden="true">&rarr;</span>
					</Link>
				</div>
			</div>
		</div>
	);
}
