import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import {
	MdDirectionsBoat,
	MdDirectionsBus,
	MdDirectionsRailway,
	MdDirectionsSubway,
	MdDirectionsTransit,
} from 'react-icons/md';

export default function TransportTypeBadge({ items, size = 'md' }) {
	const { t } = useTranslation('common');
	const sizeClasses = {
		sm: 'text-xs md:text-sm px-1.5 py-0.5 space-x-1 md:space-x-2 md:px-2.5 md:py-1',
		md: 'text-sm md:text-sm px-3 py-1 space-x-2',
	};
	const iconSizeClasses = {
		sm: 'h-3 w-3 md:h-4 md:w-4',
		md: 'h-4 w-4',
	};

	return (
		<div className="flex flex-wrap items-center gap-x-2 gap-y-2">
			{items.map((item) => (
				<div
					key={item}
					className={clsx(
						'flex shrink-0 items-center rounded-full bg-gray-100 font-medium capitalize text-gray-600 hover:bg-gray-100',
						sizeClasses[size]
					)}
				>
					{item === 'bus' && (
						<MdDirectionsBus
							aria-hidden
							className={clsx(iconSizeClasses[size])}
						/>
					)}
					{item === 'subway' && (
						<MdDirectionsSubway
							aria-hidden
							className={clsx(iconSizeClasses[size])}
						/>
					)}
					{item === 'light_rail' && (
						<MdDirectionsRailway
							aria-hidden
							className={clsx(iconSizeClasses[size])}
						/>
					)}
					{item === 'train' && (
						<MdDirectionsTransit
							aria-hidden
							className={clsx(iconSizeClasses[size])}
						/>
					)}
					<span className="capitalize">{t(`labels.${item}`)}</span>
				</div>
			))}
		</div>
	);
}

export function GTFSTransportTypeIcon({ routeType, iconProps }) {
	let Icon = null;
	switch (parseInt(routeType)) {
		case 0:
			Icon = MdDirectionsTransit;
			break;
		case 1:
			Icon = MdDirectionsSubway;
			break;
		case 2:
			Icon = MdDirectionsRailway;
			break;
		case 3:
			Icon = MdDirectionsBus;
			break;
		case 4:
			Icon = MdDirectionsBoat;
			break;
		case 5:
			break;
		case 6:
			break;
		case 7:
			break;
		case 11:
			break;
		case 12:
			break;
	}
	return <Icon {...iconProps} aria-hidden="true" />;
}
