import {
	getFeaturedCompanies,
	getFeaturedLocations,
	getFeaturedStations,
	getRegionTopRoutes,
	getRoutesTranslated,
} from '@/lib/repository.js';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import CompanyListSection from '@/components/home/CompanyListSection';
import DestinationListSection from '@/components/home/DestinationListSection';
import FeaturesSection from '@/components/home/FeaturesSection';
import Hero from '@/components/home/Hero';
import SearchHelpSection from '@/components/home/SearchHelpSection.jsx';
import StationsSections from '@/components/home/StationsSection.jsx';
import StatsSection from '@/components/home/StatsSection';
import TestimonialSection from '@/components/home/TestimonialSection.jsx';
import TopRoutesSection from '@/components/home/TopRoutesSection';
import Newsletter from '@/components/ui/Newsletter.jsx';
import { project } from '@/lib/project.js';
import {
	NextSeo,
	OrganizationJsonLd,
	SiteLinksSearchBoxJsonLd,
} from 'next-seo';
import { getCanonicalUrl } from 'routes.js';

export default function Home({
	companies,
	destinations,
	routes,
	stations,
	locale,
}) {
	const { t } = useTranslation(['home', 'common']);

	const primaryCompanies = [];
	const secondaryCompanies = [];
	companies.forEach((company) =>
		company.transportType === 'train'
			? primaryCompanies.push(company)
			: secondaryCompanies.push(company)
	);

	while (secondaryCompanies.length > 0 && primaryCompanies.length < 6) {
		primaryCompanies.push(secondaryCompanies.pop());
	}

	const canonicalURL = getCanonicalUrl('/', locale);
	return (
		<>
			<NextSeo
				title={t('home:title')}
				description={t('home:description', { project: project.name })}
				openGraph={{ url: canonicalURL }}
				canonical={canonicalURL}
				additionalMetaTags={[
					{ name: 'keywords', content: t('home:keywords') },
					{ property: 'twitter:title', content: t('home:title') },
					{
						property: 'twitter:description',
						content: t('home:description', { project: project.name }),
					},
					{
						property: 'twitter:image',
						content: `https://${project.domain}/icons/${project.id}/share.png`,
					},
					{ property: 'twitter:card', content: 'summary' },
				]}
			/>
			<OrganizationJsonLd
				type="Organization"
				id={`https://${project.domain}#organization`}
				name={project.name}
				url={canonicalURL}
				logo={`https://${project.domain}/icons/${project.id}/logo.png`}
				sameAs={[
					project.facebook,
					project.instagram,
					project.linkedIn,
					project.twitter,
					project.youtube,
				]}
				description={project.description}
				email={project.email}
				telephone={project.phone}
				vatID={project.vatID}
				foundingDate={project.foundingDate}
				address={
					project.address && {
						addressLocality: project.address.city,
						addressCountry: project.address.country,
						postalCode: project.address.zip,
						streetAddress: project.address.street,
					}
				}
			/>
			<SiteLinksSearchBoxJsonLd
				url={canonicalURL}
				name={project.name}
				potentialActions={[
					{
						target: `${getCanonicalUrl('/search', locale)}?q`,
						queryInput: 'search_term_string',
					},
				]}
			/>
			<main>
				<Hero companies={primaryCompanies} />
				<FeaturesSection />
				<TestimonialSection />
				<CompanyListSection companies={secondaryCompanies} />
				<StationsSections stations={stations} />
				<StatsSection />
				<SearchHelpSection />
				<TopRoutesSection routes={routes} />
				<DestinationListSection items={destinations} />
				<Newsletter />
			</main>
		</>
	);
}

export async function getStaticProps(props) {
	const { locale } = props;
	if (locale === 'default') {
		return {
			notFound: true,
		};
	}

	const [companies, destinations, routes, stations] = await Promise.all([
		getFeaturedCompanies({ limit: 20, locale }),
		getFeaturedLocations({ limit: 6, locale }),
		getRegionTopRoutes({ limit: 15, locale }),
		getFeaturedStations({ limit: 3, locale }),
	]);

	const translatedRoutes = await getRoutesTranslated({ routes, locale });

	return {
		props: {
			companies,
			destinations,
			routes: translatedRoutes,
			stations,
			locale,
			...(await serverSideTranslations(locale, ['home', 'common'])),
		},
	};
}
