import { project } from '@/lib/project';
import { useTranslation } from 'next-i18next';
export default function StatsSection() {
	const { t } = useTranslation(['home']);

	const stats = [
		{
			dimension: t('home:sections.stats.dimensions.travellers'),
			metric: `${project.stats.travellers}+`,
		},
		{
			dimension: t('home:sections.stats.dimensions.companies'),
			metric: `${project.stats.companies}+`,
		},
		{
			dimension: t('home:sections.stats.dimensions.cities'),
			metric: `${project.stats.cities}+`,
		},
	];

	return (
		<div className="bg-indigo-50 pt-12 sm:pt-16">
			<div className="mx-auto max-w-7xl px-4 lg:px-8">
				<div className="mx-auto max-w-4xl text-center">
					<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
						{t('home:sections.stats.title')}
					</h2>
					<p className="mt-3 text-xl text-gray-600 sm:mt-4">
						{t('home:sections.stats.subtitle')}
					</p>
				</div>
			</div>
			<div className="mt-10 bg-white pb-12 sm:pb-16">
				<div className="relative">
					<div className="absolute inset-0 h-1/2 bg-indigo-50" />
					<div className="relative mx-auto max-w-7xl px-4 lg:px-8">
						<div className="mx-auto max-w-4xl">
							<dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
								{stats.map((stat) => (
									<div
										key={stat.dimension}
										className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r"
									>
										<dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
											{stat.dimension}
										</dt>
										<dd className="order-1 text-5xl font-bold tracking-tight text-indigo-600">
											{stat.metric}
										</dd>
									</div>
								))}
							</dl>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
